import {} from '@routable/types';
import React from 'react';
import { getHeaderOrBodyCellProps, } from '../../../TablematicTable/tablematic.table.types';
import { fixCellWidths } from '../../helpers/tablematic.table.helpers';
import { RowContainer, StyledTableCell, TableRow } from './tablematic.table.rows.styled';
const InternalTableRows = ({ CustomRowComponent, dataRow, fieldProps, getAreTableRowFieldValuesEqualToInitial, getRowValues, getTableSectionFieldSize, headersConfigById, horizontalScrollGroupRef, innerWidth, isOnDetailsPage, rowContainerRef, section, }) => (React.createElement(RowContainer, { className: "tablematic-table-body w-full", key: `${section.id}.rows`, ref: rowContainerRef, style: { width: innerWidth } }, dataRow?.data?.map((row, rowIdx) => {
    const getCurrentRowValues = () => getRowValues(row.idx);
    const rowValues = getCurrentRowValues();
    const allFieldsEqualInitialValue = getAreTableRowFieldValuesEqualToInitial({
        data: getCurrentRowValues(),
        row: fieldProps,
    });
    if (CustomRowComponent) {
        return (React.createElement(TableRow, { key: `table-row-${rowIdx + 1}`, "data-testid": "tablematic-row" },
            React.createElement(CustomRowComponent, { CellComponent: StyledTableCell, fieldProps: fieldProps, fixCellWidths: fixCellWidths, getHeaderOrBodyCellProps: getHeaderOrBodyCellProps, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, isOnDetailsPage: isOnDetailsPage, row: row, rowValues: rowValues })));
    }
    return (React.createElement(TableRow, { key: `table-row-${rowIdx + 1}`, "data-testid": "tablematic-row" }, row &&
        row.cells &&
        row.cells.map((cell, cellIdx) => {
            const headerConfig = headersConfigById[cell.column.id];
            if (!headerConfig.isVisible) {
                return null;
            }
            const fieldSize = getTableSectionFieldSize(fieldProps[cell.column.id]);
            const bodyCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
            return (React.createElement(StyledTableCell, { key: `table-cell-${cellIdx + 1}`, ...cell.getCellProps(), ...bodyCellProps, isOnDetailsPage: isOnDetailsPage }, cell.render('Cell', {
                ...fieldProps[cell.column.id],
                getIsDisabled: fieldProps[cell.column.id].createGetIsDisabled(row.idx),
                getRowValues: getCurrentRowValues,
                setIsDisabled: fieldProps[cell.column.id].createGetIsDisabled(row.idx),
                horizontalScrollGroupRef: horizontalScrollGroupRef,
                id: fieldProps[cell.column.id].getId(row.idx),
                index: row.idx,
                isOnDetailsPage,
                rowValues,
                name: fieldProps[cell.column.id].getName(row.idx),
                validate: allFieldsEqualInitialValue ? undefined : fieldProps[cell.column.id].validate,
            })));
        })));
})));
export const TablematicTableRows = React.memo(InternalTableRows);
