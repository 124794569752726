import { useItemFieldMapping } from '@routable/tablematic';
import { useMemo } from 'react';
import { transformOverlayDataWithFieldMapping } from './helpers';
import { useItemOverlays } from './useItemOverlays';
export const useItemInitialDataFromOverlays = ({ enabled = true, itemId, kind, }) => {
    const { data: fieldMapping, isLoading: fieldMappingLoading } = useItemFieldMapping({ enabled, kind });
    const { data: itemOverlaysData, isLoading: overlaysLoading } = useItemOverlays({ enabled, itemId });
    const isLoading = enabled && (fieldMappingLoading || overlaysLoading);
    const initialDataFromOverlays = useMemo(() => (!enabled || isLoading ? {} : transformOverlayDataWithFieldMapping(fieldMapping, itemOverlaysData)), [enabled, fieldMapping, itemOverlaysData, isLoading]);
    return {
        isLoading,
        data: initialDataFromOverlays,
    };
};
