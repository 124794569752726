import { Search } from '@carbon/icons-react';
import { Clickable, getDataAlign, HeaderTemplate, ModalLookupTable, RadioSelect, RowSelect, Table, TableBodyTemplate, TableCell, TableHeaderCell, TableRow, Tooltip, TruncatableText, useDispatchTableEvent, useManualData, useTable, } from '@routable/components';
import { RoutableEventsSync } from '@routable/framework';
import React from 'react';
import styled from 'styled-components';
import { LineItemStyles } from 'constants/lineItems';
import { parseCurrency } from 'helpers/numbers';
export const TableCellWrapper = styled(TableCell).withConfig({
    displayName: 'TableCellWrapper',
}) `
  padding: 20px 12px;
  // The global line height is being overwritten by a component in the Table,
  // so we must set it again here.
  line-height: 1.5;
  text-overflow: ${({ truncate = true }) => (truncate ? 'ellipsis' : 'unset')};
`;
export const PoLineItemsTable = ({ isMultiSelect = true, onClose, onSubmit, purchaseOrder, submitText, tableConfig, tableData, }) => {
    const { searchData, ...results } = useManualData({
        data: tableData.data,
        tableName: tableConfig.tableName,
    });
    const tableConfigAndData = useTable(tableConfig, results);
    const isCheckAllDisabled = !results.data?.length || results.data?.every?.((lineItem) => lineItem.isDisabled);
    const changeSingleSelection = useDispatchTableEvent({
        tableName: tableConfig.tableName,
        event: 'selected:update:single',
    });
    const onSelectRow = (event, id) => {
        if (event.target.type === 'checkbox') {
            return;
        }
        changeSingleSelection([id]);
    };
    const onClearData = () => {
        RoutableEventsSync.Publish('clear-modal-lookup-table-search', {});
    };
    return (React.createElement(ModalLookupTable.TableWrapper, { isMultiSelect: isMultiSelect, onClose: onClose, onSubmit: onSubmit, setSearch: searchData, submitText: submitText, tableName: tableConfig.tableName },
        React.createElement(Table, { ...tableConfigAndData, clearSelectionOnMount: false, display: "full-width", fixedWidth: "900px", footerType: "none" },
            React.createElement(HeaderTemplate, { Template: ({ data = [] }) => (React.createElement(TableRow, null, data.map(({ isSelectObj, columnId, caption, hAlign }) => (React.createElement(TableHeaderCell, { "data-column": columnId, key: columnId, ...getDataAlign(hAlign) },
                    isSelectObj && isMultiSelect && (React.createElement(RowSelect, { isCheckAll: true, isDisabled: isCheckAllDisabled, valueKey: "purchase_order_line_item_id" })),
                    !isSelectObj && caption))))) }),
            React.createElement(TableBodyTemplate, { noDataComponent: React.createElement("div", { className: "flex items-center justify-center min-h-[160px]" },
                    React.createElement("span", { className: "text-lg text-black" }, "No items available")), noDataForFiltersComponent: React.createElement("div", { className: "flex flex-col items-center justify-center min-h-[160px]" },
                    React.createElement("div", { className: "p-3 mb-3 rounded-lg bg-grey-5" },
                        React.createElement(Search, { size: 20 })),
                    React.createElement("span", { className: "text-lg leading-6 text-black" }, "No items match your search"),
                    React.createElement("span", { className: "text-sm text-grey-60" },
                        `Try changing your search terms or `,
                        React.createElement(Clickable, { className: "text-blue-40", onClick: onClearData }, "clear your search"))), results: { ...results }, Template: ({ data }) => {
                    const { isDisabled } = data;
                    const amount = parseFloat(String(data.amount)) < 0 ? 0 : data.amount;
                    const quantity = parseFloat(String(data.quantity)) < 0 ? 0 : data.quantity;
                    const unitPrice = parseFloat(String(data.unit_price)) < 0 ? 0 : data.unit_price;
                    return (React.createElement(Tooltip, { "data-testid": `link-disabled-tooltip-${data.purchase_order_line_item_id}`, tooltip: isDisabled
                            ? "You can't add this PO line because it's already linked to another bill line."
                            : undefined, variant: "light" },
                        React.createElement(TableRow, { isDisabled: isDisabled, key: data.purchase_order_line_item_id, onClick: isDisabled ? undefined : (e) => onSelectRow(e, data.purchase_order_line_item_id) },
                            React.createElement(TableCellWrapper, { column: "id" }, !isMultiSelect ? (React.createElement(RadioSelect, { isDisabled: isDisabled, value: data.purchase_order_line_item_id })) : (React.createElement(RowSelect, { isDisabled: isDisabled, value: data.purchase_order_line_item_id }))),
                            React.createElement(TableCellWrapper, { column: "productName" },
                                React.createElement(TruncatableText, { className: "font-medium", text: data[data.style].name })),
                            React.createElement(TableCellWrapper, { column: "description" },
                                React.createElement(TruncatableText, { text: data.description })),
                            data.style === LineItemStyles.ITEM && (React.createElement(React.Fragment, null,
                                React.createElement(TableCellWrapper, { className: "text-right", column: "unit_price", truncate: false },
                                    React.createElement("div", null, `${parseCurrency(unitPrice, { symbol: '' })} ${purchaseOrder.currencyCode}`)),
                                React.createElement(TableCellWrapper, { className: "text-center", column: "quantity", truncate: false },
                                    React.createElement("div", null, quantity)))),
                            React.createElement(TableCellWrapper, { className: "text-right", column: "amount", truncate: false },
                                React.createElement("div", null, `${parseCurrency(amount, { symbol: '' })} ${purchaseOrder.currencyCode}`)))));
                } }))));
};
