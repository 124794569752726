import {} from '@routable/types';
import React from 'react';
import { TruncatableText } from '../../../TruncatableText';
import { fixCellWidths } from '../../helpers/tablematic.table.helpers';
import { getHeaderOrBodyCellProps } from '../../tablematic.table.types';
import { HeaderContainer, StyledHeaderCell } from './tablematic.table.header.styled';
const InternalTableHeader = ({ fieldProps, getTableSectionFieldSize, headerContainerRef, headersConfigById, isOnDetailsPage, section, }) => (React.createElement(React.Fragment, null,
    React.createElement(HeaderContainer, { className: "tablematic-table-header-container w-full", key: `${section.id}.headers`, ref: headerContainerRef }, section?.headerIds.map((headerId, idx) => {
        const headerConfig = headersConfigById?.[headerId];
        if (!headerConfig.isVisible) {
            return null;
        }
        const header = section.headersById?.[headerId];
        const fieldSize = getTableSectionFieldSize(fieldProps[headerId]);
        const headerCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
        const isRequired = Boolean(fieldProps?.[headerId]?.getIsRequired?.());
        return (React.createElement(StyledHeaderCell, { key: `header-cell-${headerId || idx}`, ...header.getHeaderProps?.(), ...headerCellProps, isCurrencyField: fieldProps[headerId]?.minDecimalScale > 0, isOnDetailsPage: isOnDetailsPage, isQuantityField: headerId.includes('quantity') },
            React.createElement("div", { className: "flex min-w-0" },
                React.createElement(TruncatableText, { className: "header-cell-text", dataFullStory: false, position: "top", text: header.render('Header') }),
                !isOnDetailsPage && isRequired && React.createElement("span", { className: "text-blue-40" }, "*"))));
    }))));
export const TablematicTableHeader = React.memo(InternalTableHeader);
