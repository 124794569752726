import { apiCall } from '@routable/framework';
import { OverlaysApiResponseModel } from './overlays.models';
export const generateOverlaysApi = (api) => ({
    getItemOverlays: async (itemId) => api({
        camelCaseResponse: true,
        url: `overlays/item/${itemId}/`,
        method: 'get',
        responseModel: OverlaysApiResponseModel,
    }),
});
export const overlaysApi = generateOverlaysApi(apiCall);
