import React from 'react';
import { formNamesFunding } from 'constants/forms';
import { useBankAccountReceivingCurrency } from 'hooks';
import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';
import { itemCurrencyCodeReceiverCompoundSelector } from 'queries/itemCompoundSelectors';
import {} from './AccountCurrencyCodeField.types';
const AccountCurrencyCodeField = ({ availableCurrencies, partnership }) => {
    const { fieldLabel, fieldName, isLocked, tooltipText, options } = useBankAccountReceivingCurrency({
        formName: formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
        availableCurrencies,
        itemCurrencyCodeSelector: itemCurrencyCodeReceiverCompoundSelector,
        partnershipSelector: () => partnership,
    });
    return (React.createElement(VendorReceivingCurrency, { className: "margin-top--m-large", fieldLabel: fieldLabel, fieldName: fieldName, isLocked: isLocked, options: options, tooltipText: tooltipText }));
};
export default AccountCurrencyCodeField;
