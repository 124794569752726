import React from 'react';
import { ButtonV2, TooltipMUIConditionalWrapper } from 'components';
import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';
import { AddTaxFormButtonDisplayType } from './AddTaxFormButton.enums';
const AddTaxFormButton = ({ displayType, isLoading, isDisabled, onClick, tooltipProps, label = 'Add tax form', }) => {
    const size = displayType === AddTaxFormButtonDisplayType.LARGE ? ButtonSize.MEDIUM : ButtonSize.SMALL;
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: isDisabled, isLoading: isLoading, onClick: onClick, size: size }, label)));
};
export default AddTaxFormButton;
