import React from 'react';
import { Image } from 'components/media';
import { Emoji, Text } from 'components/text';
import { typography } from 'constants/styles';
import {} from './ListEmpty.types';
const ListEmpty = ({ content, emoji, image, message }) => (React.createElement("div", { className: "display--flex flex-direction--column align-items--center list-empty" },
    React.createElement(Image, { alt: "No results", src: image }),
    React.createElement(Text.Bold, { className: "display--flex justify-content--center line-height--25--important margin-top--large", color: typography.TextColor.GREY_XX_DARK, size: typography.TextSize.LEVEL_500 },
        message,
        emoji && React.createElement(Emoji, { ariaLabel: "Hey", className: "margin-left--sm", text: emoji })),
    content && (React.createElement(Text.Regular, { className: "display--flex margin-top--sm white-space-pre-wrap justify-content--center line-height--25--important", color: typography.TextColor.GREY_XX_DARK, size: typography.TextSize.LEVEL_200 }, content))));
export default ListEmpty;
