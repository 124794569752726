import classNames from 'classnames';
import React from 'react';
import { ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';
import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';
import { TaxInfoRequestButtonDisplayType } from './TaxInfoRequestButton.enums';
import './TaxInfoRequestButton.scss';
const TaxInfoRequestButton = ({ className, displayType = TaxInfoRequestButtonDisplayType.COMPACT, isDisabled, isLoading, onClick, tooltipProps, label = 'Request from vendor', }) => {
    const size = displayType === TaxInfoRequestButtonDisplayType.LARGE ? ButtonSize.MEDIUM : ButtonSize.SMALL;
    const iconProps = displayType === TaxInfoRequestButtonDisplayType.COMPACT_WITH_ICON
        ? {
            leftIconClassName: 'margin-right--xm',
            leftIconColor: colors.colorDarkSilverHex,
            leftIconName: IconNames.ENVELOPE,
        }
        : {};
    return (React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
        React.createElement(ButtonV2, { ...iconProps, className: classNames('request-tax-info-button', `request-tax-info-button--${displayType}`, className), intent: Intent.NEUTRAL, isDisabled: isDisabled, isLoading: isLoading, onClick: onClick, size: size }, label)));
};
export default TaxInfoRequestButton;
