import React from 'react';
import { useDispatch } from 'react-redux';
import { openAddTaxFormSidePanel } from 'ducks/tax/taxInput';
import PermissionResourceAction from 'constants/permissions';
import { usePermissions } from 'hooks';
import AddTaxFormButton from './AddTaxFormButton';
import {} from './AddTaxFormButton.types';
const AddTaxFormButtonContainer = ({ displayType, label }) => {
    const dispatch = useDispatch();
    const permissions = usePermissions([PermissionResourceAction.TAX_TEAM_MEMBER_ENTRY]);
    const tooltipProps = !permissions.hasPermission ? { ...permissions.tooltipProps, padding: undefined } : undefined;
    return (React.createElement(AddTaxFormButton, { displayType: displayType, isDisabled: !permissions.hasPermission, isLoading: false, label: label, onClick: () => dispatch(openAddTaxFormSidePanel({})), tooltipProps: tooltipProps }));
};
export default AddTaxFormButtonContainer;
