import {} from '@routable/types';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { TablematicTableSection } from './tablematic.table.section.component';
import { Container } from './tablematic.table.styled';
import {} from './tablematic.table.types';
export const TablematicTable = ({ activeTableSectionIds, getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize, isOnDetailsPage, rowData, tableSections, }) => {
    const [dataRows, setDataRows] = useState({});
    const sectionRefs = useRef([]);
    const totalRowCount = Math.max(...activeTableSectionIds.map((sectionId) => rowData[sectionId].data.length));
    useEffect(() => {
        sectionRefs.current = activeTableSectionIds
            ? activeTableSectionIds.map((_, idx) => sectionRefs.current[idx] || React.createRef())
            : [];
    }, [activeTableSectionIds]);
    useEffect(() => {
        const updatedDataRows = activeTableSectionIds.reduce((obj, sectionId) => {
            const dataItem = rowData[sectionId];
            const firstRow = _cloneDeep(dataItem.data[0]);
            const newData = [];
            for (let rowIdx = 0; rowIdx < totalRowCount; rowIdx++) {
                if (dataItem.data[rowIdx]) {
                    tableSections[sectionId].tableProps.prepareRow(dataItem.data[rowIdx]);
                    newData.push({ ...dataItem.data[rowIdx], isEmpty: false });
                }
                else if (firstRow) {
                    const cloneRow = _cloneDeep(firstRow);
                    cloneRow.id = `${rowIdx}`;
                    cloneRow.isEmpty = true;
                    tableSections[sectionId].tableProps.prepareRow(cloneRow);
                    newData.push(cloneRow);
                }
            }
            obj[sectionId] = { ...dataItem, data: newData };
            return obj;
        }, {});
        setDataRows(updatedDataRows);
    }, [rowData]);
    return (React.createElement(Container, { className: "tablematic-table-outside-body" }, activeTableSectionIds?.map((sectionId, sectionIdx) => {
        return (React.createElement(TablematicTableSection, { dataRow: dataRows?.[sectionId], getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize: getTableSectionFieldSize, isOnDetailsPage: isOnDetailsPage, key: sectionId, sectionRef: sectionRefs.current[sectionIdx], tableSection: tableSections[sectionId] }));
    })));
};
