import { formNamesItem } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { CurrencyInputField } from 'components/form/NumericInput/CurrencyInputField.component';
import { DiscrepanciesObjectKeyMap } from 'constants/discrepancies';
import { formatNumberForFlexibleDecimalScale } from 'helpers/fieldFormatters';
import { useDiscrepancyContext, useMemoizedConditionalValidate } from 'hooks';
import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormPurchaseOrderSelector } from 'selectors/forms';
const CurrencyTableField = (props) => {
    const { currencyCode, decimalScale, fixedDecimalScale, formValues, getIsDisabled, getIsRequired, getRowValues, minDecimalScale, name, setIsDisabled, validate, } = props;
    const { isRequired, validators } = useDynamicFieldRequiredValidators({
        formValues,
        getIsRequired,
        getRowValues,
        validate,
    });
    const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
    const isDisabled = useTableCellFieldIsDisabled({ formValues, getIsDisabled, setIsDisabled });
    const suffix = currencyCode ? ` ${currencyCode}` : '';
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const { hasDiscrepancyForColumn } = useDiscrepancyContext();
    const fieldSelector = createFieldValueSelector(formNamesItem.CREATE_ITEM, name.split('.').slice(0, -1).join('.'));
    const lineItem = useSelector(fieldSelector);
    const [fieldName] = name.split('.').slice(-1);
    const lineItemId = lineItem?.id || lineItem?.purchase_order_line_item_id;
    const hasDiscrepancy = purchaseOrder?.value && hasDiscrepancyForColumn(lineItemId, DiscrepanciesObjectKeyMap[fieldName]);
    return (React.createElement(Field, { allowNegative: true, className: "w-full ledger-form-field", component: CurrencyInputField, contentBefore: hasDiscrepancy && (React.createElement("div", { className: clsx('absolute border border-gold-40 rounded-[13px] inset-y-[6px] inset-x-[4px]', {
                'z-[-1]': !isDisabled,
            }), "data-testid": "discrepancy-border" })), decimalScale: decimalScale, fixedDecimalScale: fixedDecimalScale, format: (value) => formatNumberForFlexibleDecimalScale({
            decimalScale,
            fixedDecimalScale,
            minDecimalScale,
            value,
        }), hidePlaceholder: true, inputClassName: "text-right", isDebounced: true, isDisabled: isDisabled, isRequired: isRequired, name: name, normalize: (value) => value ?? '', suffix: suffix, type: "text", validate: cachedValidators }));
};
export default CurrencyTableField;
