/**
 * @module data/parse/item/structure
 */

import { SectionType } from '@routable/tablematic';
import _groupBy from 'lodash/groupBy';

import { ItemKinds } from 'constants/item';
import { tableNamesCreateItems } from 'constants/tables';

import { isApplicationTypeXero } from 'helpers/ledger';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { firstValue } from 'helpers/utility';

import { payloadToCamelCase } from 'services/api/formatHelpers';

/**
 * Given the original, un-formatted response data, returns the parsed + formatted configs for
 * each table in CreateItems.
 * @param {Object[]} originalResponseData
 * @return {Object.<string, Object>}
 */
export const getParsedCreateItemsTableConfigs = (originalResponseData = {}, ledger) => {
  const configsByKind = _groupBy(originalResponseData, 'attributes.kind');

  const dataObjectsByKind = {
    [ItemKinds.PAYABLE]: firstValue(configsByKind[ItemKinds.PAYABLE]),
    [ItemKinds.RECEIVABLE]: firstValue(configsByKind[ItemKinds.RECEIVABLE]),
  };

  const parsedConfigsByKind = getObjectsByIdWithRelationships(dataObjectsByKind);

  const payableSchema = payloadToCamelCase(parsedConfigsByKind[ItemKinds.PAYABLE]?.data);
  let payableDetailsSchema = {};
  let payableDetailsTableSchema = {};

  if (payableSchema) {
    const mainSection = payableSchema.sections.find(({ id }) => id === 'main');
    const tabbedSection = payableSchema.sections.find(({ id }) => id === 'tabs');
    const otherSection = payableSchema.sections.find(({ id }) => id === 'other');
    const lineItemsSection = payableSchema.sections.find(({ id }) => id === 'line_items');

    const hasLedgerIntegration = !!ledger?.id;
    const isLedgerXero = isApplicationTypeXero(ledger?.application);

    payableDetailsTableSchema = {
      sections: [
        {
          ...lineItemsSection,
          id: 'lineItems',
          sections: [
            ...lineItemsSection.sections.map((section) => {
              const billLineItemsSection = section.sections.find(
                ({ id }) => id === 'line_items_account' || id === 'line_items_item',
              );

              return {
                ...section,
                display: billLineItemsSection.display,
                constraints: null,
                sections: section.sections
                  .filter(({ id }) => !id.includes('_actions'))
                  .map((tableSection) => ({
                    ...tableSection,
                    constraints: null,
                    sectionType: SectionType.presentationalTable,
                  })),
              };
            }),
          ],
        },
      ],
    };

    payableDetailsSchema = {
      sections: [
        ...mainSection.sections.map((section) => ({
          ...section,
          display: null,
          fields: [
            ...section.fields.map((field) => ({ ...field, size: 1 })),
            ...(otherSection
              ? otherSection.sections.map(({ fields }) => fields.map((field) => ({ ...field, size: 1 }))).flat()
              : []),
            ...(hasLedgerIntegration
              ? [
                  {
                    display: 'Ledger status',
                    fieldType: 'text',
                    id: 'ledger_status',
                    key: 'ledger_status',
                    size: 1,
                  },
                ]
              : []),
            ...(isLedgerXero
              ? [
                  {
                    display: 'Tags',
                    fieldType: 'text',
                    id: 'tag_links',
                    key: 'tag_links',
                    size: 1,
                  },
                ]
              : []),
          ],
          sectionType: SectionType.presentational_block, // presentational_table
        })),
        ...(tabbedSection
          ? [
              {
                ...tabbedSection,
                sections: tabbedSection.sections.map((section) => ({
                  ...section,
                  sections: section.sections.map((subsection) => ({
                    ...subsection,
                    fields: subsection.fields.map((field) => ({
                      ...field,
                      // max 4 per row, if less than 4 stretch to fill row
                      size: Math.max(Math.floor(20 / subsection.fields.length), 5),
                    })),
                    sectionType: SectionType.presentational_block,
                  })),
                })),
              },
            ]
          : []),
      ],
    };
  }

  return {
    [tableNamesCreateItems.PAYABLE]: payloadToCamelCase(parsedConfigsByKind[ItemKinds.PAYABLE]?.data),
    [tableNamesCreateItems.RECEIVABLE]: payloadToCamelCase(parsedConfigsByKind[ItemKinds.RECEIVABLE]?.data),
    payableDetailsSchema,
    payableDetailsTableSchema,
  };
};
