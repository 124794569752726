import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { internationalBankValidatorsReset } from 'actions/funding';
import { ReduxFormFieldRenderInput } from 'components';
import { colors } from 'constants/styles';
import { requiredValidator } from 'helpers/fieldValidation';
import { snakeCaseFundingFieldsToExternalUserCase } from 'helpers/funding';
import { IsLoadingInline } from 'modules/isLoading';
import { useInternationalBankValidator } from '../../hooks';
import {} from './ManuallyAddBankAccountField.types';
const ManuallyAddBankAccountField = ({ fieldInfo, isInternational }) => {
    const dispatch = useDispatch();
    const fieldName = fieldInfo.field;
    const placeholder = snakeCaseFundingFieldsToExternalUserCase(fieldName);
    const { bankNameNode, fieldValidator, isValidating, onBlur } = useInternationalBankValidator(fieldName, isInternational);
    useEffect(() => () => {
        dispatch(internationalBankValidatorsReset({ fieldName }));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { addon: isValidating ? React.createElement(IsLoadingInline, { color: colors.colorDarkSilverHex }) : null, className: "margin-top--m-large", component: ReduxFormFieldRenderInput, isRequired: true, name: fieldName, onBlur: onBlur, placeholder: placeholder, validate: [requiredValidator, fieldValidator] }),
        bankNameNode));
};
export default ManuallyAddBankAccountField;
