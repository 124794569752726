export const CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL = 'CLEAR_FUNDING_ACCOUNT_ADDRESS_MANUAL';
export const CREATE_INTERNATIONAL_FUNDING_ACCOUNT = '@funding/CREATE_INTERNATIONAL_FUNDING_ACCOUNT';
export const CREATE_INTERNATIONAL_BANK_ACCOUNT = '@funding/CREATE_INTERNATIONAL_BANK_ACCOUNT';
export const FETCH_SINGLE_FUNDING_ACCOUNT_FAILURE = 'FETCH_SINGLE_FUNDING_ACCOUNT_FAILURE';
export const FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST = 'FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST';
export const FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS = 'FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS';
export const FETCH_FUNDING_ACCOUNTS_FAILURE = 'FETCH_FUNDING_ACCOUNTS_FAILURE';
export const FETCH_FUNDING_ACCOUNTS_REQUEST = 'FETCH_FUNDING_ACCOUNTS_REQUEST';
export const FETCH_FUNDING_ACCOUNTS_SUCCESS = 'FETCH_FUNDING_ACCOUNTS_SUCCESS';
export const MODAL_ADD_PARTNER_FUNDING_ACCOUNT_CLOSE = 'MODAL_ADD_PARTNER_FUNDING_ACCOUNT_CLOSE';
export const MODAL_ADD_PARTNER_FUNDING_ACCOUNT_OPEN = 'MODAL_ADD_PARTNER_FUNDING_ACCOUNT_OPEN';
export const MODAL_BANK_ACCOUNT_DETAILS_CLOSE = 'MODAL_BANK_ACCOUNT_DETAILS_CLOSE';
export const MODAL_BANK_ACCOUNT_DETAILS_OPEN = 'MODAL_BANK_ACCOUNT_DETAILS_OPEN';
export const MODAL_VERIFY_FUNDING_ACCOUNT_CLOSE = 'MODAL_VERIFY_FUNDING_ACCOUNT_CLOSE';
export const MODAL_VERIFY_FUNDING_ACCOUNT_OPEN = 'MODAL_VERIFY_FUNDING_ACCOUNT_OPEN';
export const SUBMIT_BALANCE_TRANSACTION_FAILURE = 'SUBMIT_BALANCE_TRANSACTION_FAILURE';
export const SUBMIT_BALANCE_TRANSACTION_REQUEST = 'SUBMIT_BALANCE_TRANSACTION_REQUEST';
export const SUBMIT_BALANCE_TRANSACTION_SUCCESS = 'SUBMIT_BALANCE_TRANSACTION_SUCCESS';
export const SUBMIT_CONVERT_FUNDING_ACCOUNT_FAILURE = 'SUBMIT_CONVERT_FUNDING_ACCOUNT_FAILURE';
export const SUBMIT_CONVERT_FUNDING_ACCOUNT_REQUEST = 'SUBMIT_CONVERT_FUNDING_ACCOUNT_REQUEST';
export const SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS = 'SUBMIT_CONVERT_FUNDING_ACCOUNT_SUCCESS';
export const SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE = 'SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_FAILURE';
export const SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST = 'SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_REQUEST';
export const SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS = 'SUBMIT_FUNDING_ACCOUNT_ADDRESS_MANUAL_SUCCESS';
export const SUBMIT_VERIFY_FUNDING_ACCOUNT_FAILURE = 'SUBMIT_VERIFY_FUNDING_ACCOUNT_FAILURE';
export const SUBMIT_VERIFY_FUNDING_ACCOUNT_REQUEST = 'SUBMIT_VERIFY_FUNDING_ACCOUNT_REQUEST';
export const SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS = 'SUBMIT_VERIFY_FUNDING_ACCOUNT_SUCCESS';
export const UPDATE_FUNDING_ACCOUNT = 'UPDATE_FUNDING_ACCOUNT';
export const FETCH_SUPPORTED_COUNTRIES = '@funding/FETCH_SUPPORTED_COUNTRIES';
export const FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_FAILURE = 'FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_FAILURE';
export const FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_RESET = 'FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_RESET';
export const FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_SUCCESS = 'FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_SUCCESS';
