import React from 'react';
import { Field } from 'redux-form';
import { SelectFieldV2 } from 'components/selectV2';
import { isLength } from 'helpers/utility';
import { usePartnershipPaymentOptions } from 'hooks';
import CurrencyCodeBlockFieldTooltipText from './components/CurrencyCodeBlockFieldTooltipText';
import {} from './CurrencyCodeBlockField.types';
const skipValidation = () => undefined;
const CurrencyCodeBlockField = ({ companyIntegrationSettings, formValues, getIsDisabled, ledger, partnership, ...rest }) => {
    const { currencyCodesBillOptions, isFetching } = usePartnershipPaymentOptions({ partnershipId: partnership?.id });
    const onlyOneOptionAvailable = isLength(currencyCodesBillOptions, 1);
    const isDisabled = getIsDisabled(formValues);
    return (React.createElement(Field, { component: SelectFieldV2, options: currencyCodesBillOptions, ...rest, isDisabled: isDisabled || isFetching || onlyOneOptionAvailable, isLoading: isFetching, isLocked: onlyOneOptionAvailable, lockedTooltipProps: {
            title: (React.createElement(CurrencyCodeBlockFieldTooltipText, { companyIntegrationSettings: companyIntegrationSettings, countryCodePartner: partnership?.countryCodePartner, currencyCodesBillOptions: currencyCodesBillOptions, ledger: ledger, onlyOneOptionAvailable: onlyOneOptionAvailable })),
        }, validate: [skipValidation] }));
};
export default CurrencyCodeBlockField;
