import React from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { ButtonV2, DashboardToDashboardW9RestrictionTooltipContent, TooltipMUIConditionalWrapper } from 'components';
import { Hint } from 'components/hint';
import { Text } from 'components/text';
import { ButtonSize } from 'constants/button';
import PermissionResourceAction from 'constants/permissions';
import { COMPANIES_ROUTE, SELECTED_COMPANY_TABS } from 'constants/routes';
import { TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { isPartnershipRoutableToRoutable } from 'helpers/partnerships';
import { getJoinedPath } from 'helpers/routeHelpers';
import { usePermissions } from 'hooks';
import { AddTaxFormButton } from 'modules/dashboard/company/companyTaxInfo/components';
import { AddTaxFormButtonDisplayType } from 'modules/dashboard/company/companyTaxInfo/components/AddTaxFormButton/AddTaxFormButton.enums';
const RequestContactForTaxesHint = ({ partnership }) => {
    const history = useHistory();
    const permissions = usePermissions([PermissionResourceAction.VENDOR_EDIT]);
    const handleClick = () => {
        const match = matchPath(history.location.pathname, {
            path: `${COMPANIES_ROUTE}/:filter/:id/:tab`,
        });
        const path = getJoinedPath(COMPANIES_ROUTE, match.params.filter, partnership.id, SELECTED_COMPANY_TABS.ABOUT);
        history.push(path);
    };
    const isSelfManaged = isPartnershipRoutableToRoutable(partnership);
    const isButtonDisabled = isSelfManaged || !permissions.hasPermission;
    let tooltipProps;
    if (isSelfManaged) {
        tooltipProps = {
            arrow: true,
            title: (React.createElement(DashboardToDashboardW9RestrictionTooltipContent, { actionText: " to add contact details and request W-9 info" })),
        };
    }
    else if (!permissions.hasPermission) {
        tooltipProps = { ...permissions.tooltipProps, padding: undefined };
    }
    return (React.createElement(Hint, { className: "margin--m", intent: Intent.WARNING, shouldRemoveBackgroundColor: true },
        React.createElement(Text.Bold, { className: "margin-bottom--xs", color: "var(--color-blue70)", size: TextSize.LEVEL_200 }, `You don't have tax information recorded for this vendor`),
        React.createElement("p", { className: "hint--content", style: { color: 'var(--color-blue70)' } }, "Add a company contact with a reachable email to request a tax form from your vendor."),
        React.createElement("div", { className: "flex-row items-center" },
            React.createElement(TooltipMUIConditionalWrapper, { tooltipProps: tooltipProps },
                React.createElement(ButtonV2, { className: "margin-bottom--sm margin-top--sm margin-right--large", intent: Intent.NEUTRAL, isDisabled: isButtonDisabled, onClick: handleClick, size: ButtonSize.MEDIUM }, "Go to contacts")),
            React.createElement(AddTaxFormButton, { displayType: AddTaxFormButtonDisplayType.LARGE }))));
};
export default RequestContactForTaxesHint;
