export var PAYMENTS_LIST_TABS;
(function (PAYMENTS_LIST_TABS) {
    PAYMENTS_LIST_TABS["INBOX"] = "inbox";
    PAYMENTS_LIST_TABS["PAYABLES"] = "payables";
    PAYMENTS_LIST_TABS["RECEIVABLES"] = "receivables";
    PAYMENTS_LIST_TABS["RECENT"] = "recent";
})(PAYMENTS_LIST_TABS || (PAYMENTS_LIST_TABS = {}));
export var PAYMENTS_LIST_FILTERS;
(function (PAYMENTS_LIST_FILTERS) {
    PAYMENTS_LIST_FILTERS["ALL"] = "all";
    PAYMENTS_LIST_FILTERS["COMPLETED"] = "completed";
    PAYMENTS_LIST_FILTERS["COMPLETED_AND_EXTERNALLY_PAID"] = "completed_and_externally_paid";
    PAYMENTS_LIST_FILTERS["CREATED"] = "created";
    PAYMENTS_LIST_FILTERS["FAILED_AND_ISSUE"] = "failed_and_issue";
    PAYMENTS_LIST_FILTERS["INITIATED"] = "initiated";
    PAYMENTS_LIST_FILTERS["MY_APPROVAL"] = "needs_my_approval";
    PAYMENTS_LIST_FILTERS["NEEDS_APPROVAL"] = "needs_approval";
    PAYMENTS_LIST_FILTERS["PO_DISCREPANCY"] = "po_discrepancy_hold";
    PAYMENTS_LIST_FILTERS["TEAM_APPROVAL"] = "needs_team_approval";
    PAYMENTS_LIST_FILTERS["READY_TO_SEND"] = "ready_to_send";
    PAYMENTS_LIST_FILTERS["SCHEDULED"] = "scheduled";
    PAYMENTS_LIST_FILTERS["WAITING_FOR_ME"] = "waiting_for_me";
    PAYMENTS_LIST_FILTERS["WAITING_FOR_OTHERS"] = "waiting_for_others";
})(PAYMENTS_LIST_FILTERS || (PAYMENTS_LIST_FILTERS = {}));
export var ITEM_DETAILS_FILTERS;
(function (ITEM_DETAILS_FILTERS) {
    ITEM_DETAILS_FILTERS["ITEM"] = "item";
    ITEM_DETAILS_FILTERS["PARTNER"] = "partner";
    ITEM_DETAILS_FILTERS["MY_DETAILS"] = "my_details";
})(ITEM_DETAILS_FILTERS || (ITEM_DETAILS_FILTERS = {}));
export var CONTACTS_TABS;
(function (CONTACTS_TABS) {
    CONTACTS_TABS["COMPANIES"] = "companies";
})(CONTACTS_TABS || (CONTACTS_TABS = {}));
export var COMPANIES_LIST_FILTERS;
(function (COMPANIES_LIST_FILTERS) {
    COMPANIES_LIST_FILTERS["ALL"] = "all";
    COMPANIES_LIST_FILTERS["VENDORS"] = "vendors";
    COMPANIES_LIST_FILTERS["CUSTOMERS"] = "customers";
    COMPANIES_LIST_FILTERS["INVITES"] = "invites";
})(COMPANIES_LIST_FILTERS || (COMPANIES_LIST_FILTERS = {}));
export var SELECTED_COMPANY_TABS;
(function (SELECTED_COMPANY_TABS) {
    SELECTED_COMPANY_TABS["ABOUT"] = "about";
    SELECTED_COMPANY_TABS["CUSTOMER_INFO"] = "customer_info";
    SELECTED_COMPANY_TABS["PAYMENTS"] = "payments";
    SELECTED_COMPANY_TABS["TAX_INFO"] = "tax_info";
    SELECTED_COMPANY_TABS["VENDOR_INFO"] = "vendor_info";
})(SELECTED_COMPANY_TABS || (SELECTED_COMPANY_TABS = {}));
export var GUIDE_TABS;
(function (GUIDE_TABS) {
    GUIDE_TABS["BANK_CONNECTIONS"] = "connect_bank";
    GUIDE_TABS["COMPANY_BRANDING"] = "company_branding";
    GUIDE_TABS["COMPANY_INFO"] = "company_info";
    GUIDE_TABS["COMPANY_LEGAL"] = "company_legal";
    GUIDE_TABS["COMPANY_REPS"] = "company_representatives";
    GUIDE_TABS["CONNECT_LEDGER"] = "connect_ledger";
    GUIDE_TABS["ENABLE_PAYMENTS"] = "enable_payments";
    GUIDE_TABS["INVITE_TEAM"] = "invite_team";
    GUIDE_TABS["USER_AVATAR"] = "user_avatar";
    GUIDE_TABS["VERIFY_ACCOUNT"] = "verify_account";
})(GUIDE_TABS || (GUIDE_TABS = {}));
export var SETTINGS_TABS;
(function (SETTINGS_TABS) {
    SETTINGS_TABS["ACCOUNT"] = "account";
    SETTINGS_TABS["USER"] = "user";
    SETTINGS_TABS["BILLING"] = "billing";
})(SETTINGS_TABS || (SETTINGS_TABS = {}));
export var SETTINGS_ACCOUNT_FILTERS;
(function (SETTINGS_ACCOUNT_FILTERS) {
    SETTINGS_ACCOUNT_FILTERS["API_ACCESS"] = "api_access";
    SETTINGS_ACCOUNT_FILTERS["BALANCE"] = "balance";
    SETTINGS_ACCOUNT_FILTERS["BANK_CONNECTIONS"] = "company_bank_connections";
    SETTINGS_ACCOUNT_FILTERS["BULK_UPLOADS"] = "bulk_upload_history";
    SETTINGS_ACCOUNT_FILTERS["COMPANY_BRANDING"] = "company_branding";
    SETTINGS_ACCOUNT_FILTERS["COMPANY_INFO"] = "company_info";
    SETTINGS_ACCOUNT_FILTERS["COMPANY_LEGAL"] = "company_legal";
    SETTINGS_ACCOUNT_FILTERS["COMPANY_REP_INFO"] = "company_auth_rep_info";
    SETTINGS_ACCOUNT_FILTERS["INACTIVE"] = "inactive";
    SETTINGS_ACCOUNT_FILTERS["INACTIVE_TEAM_MEMBER"] = "inactive_team_member";
    SETTINGS_ACCOUNT_FILTERS["INTEGRATIONS"] = "integrations";
    SETTINGS_ACCOUNT_FILTERS["INVITED"] = "invited";
    SETTINGS_ACCOUNT_FILTERS["INVITE_TEAM_MEMBER"] = "invite_team_member";
    SETTINGS_ACCOUNT_FILTERS["PAYABLE_CONFIGURATIONS"] = "payable_configurations";
    SETTINGS_ACCOUNT_FILTERS["PAYMENT_METHODS"] = "payment_methods";
    SETTINGS_ACCOUNT_FILTERS["REACTIVATE_TEAM_MEMBER"] = "reactivate";
    SETTINGS_ACCOUNT_FILTERS["TEAM_MEMBERS"] = "team_members";
    SETTINGS_ACCOUNT_FILTERS["BULK_UPLOAD_HISTORY"] = "bulk_upload_history";
})(SETTINGS_ACCOUNT_FILTERS || (SETTINGS_ACCOUNT_FILTERS = {}));
export var SETTINGS_USER_FILTERS;
(function (SETTINGS_USER_FILTERS) {
    SETTINGS_USER_FILTERS["PROFILE"] = "profile";
    SETTINGS_USER_FILTERS["REPRESENTATIVE"] = "company_representative";
    SETTINGS_USER_FILTERS["SECURITY"] = "security";
    SETTINGS_USER_FILTERS["NOTIFICATIONS"] = "notifications";
})(SETTINGS_USER_FILTERS || (SETTINGS_USER_FILTERS = {}));
export var PARAMS;
(function (PARAMS) {
    PARAMS["PAGE"] = ":page?";
    PARAMS["TAB"] = ":tab?";
    PARAMS["FILTER"] = ":filter?";
    PARAMS["ID"] = ":id?";
    PARAMS["ITEM_FILTER"] = ":itemFilter?";
    PARAMS["KIND"] = ":kind?";
})(PARAMS || (PARAMS = {}));
export var MODALS;
(function (MODALS) {
    MODALS["CREATE_PARTNERSHIP"] = "create_partnership";
    MODALS["REP_INFO"] = "rep_info";
})(MODALS || (MODALS = {}));
export var CREATE_ITEM_TABS;
(function (CREATE_ITEM_TABS) {
    CREATE_ITEM_TABS["CREATE_BILL"] = "create_bill";
    CREATE_ITEM_TABS["CREATE_PAYABLE"] = "create_payable";
    CREATE_ITEM_TABS["CREATE_RECEIVABLE"] = "create_receivable";
    CREATE_ITEM_TABS["BATCH"] = "batch/import/items";
})(CREATE_ITEM_TABS || (CREATE_ITEM_TABS = {}));
export var CREATE_ITEM_STATES;
(function (CREATE_ITEM_STATES) {
    CREATE_ITEM_STATES["AWAITING_PAYMENT"] = "awaiting_payment";
    CREATE_ITEM_STATES["BULK_UPLOAD"] = "batch";
    CREATE_ITEM_STATES["EDIT"] = "edit";
    CREATE_ITEM_STATES["NEW"] = "new";
})(CREATE_ITEM_STATES || (CREATE_ITEM_STATES = {}));
export var AdmissionRoutes;
(function (AdmissionRoutes) {
    AdmissionRoutes["PERMISSIONS"] = "permissions";
    AdmissionRoutes["ROLES"] = "roles";
})(AdmissionRoutes || (AdmissionRoutes = {}));
