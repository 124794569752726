import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { useCallback, useEffect, useState } from 'react';
import { useTableStore } from '../table.store';
import {} from '../table.types';
const createEmptyArrayItems = (count) => {
    return Array.from({ length: count }, () => null);
};
export const useManualData = ({ data: fullData, initialPage = { page: 1, pageSize: 25, total: 0 }, isLoading, reactTableSagaActions, setPersistantPageSize, tableName, }) => {
    const { setTableStateItem } = useTableStore(tableName);
    const [page, setPage] = useState(initialPage);
    const [results, setResults] = useState({
        data: [],
        error: null,
        filters: { search: '' },
        isError: false,
        isFetching: false,
        isLoading: true,
        pagination: {
            ...initialPage,
            hasNextPage: false,
            hasPrevPage: false,
            total: 0,
        },
        refetch: () => {
        },
        setPageSize: (pageSize) => {
            setPage({ page: 1, pageSize });
            setPersistantPageSize?.(pageSize);
        },
        gotoPage: (dest) => setPage((prev) => {
            if (dest === 'NEXT') {
                return { ...prev, page: prev.page + 1 };
            }
            if (dest === 'PREV') {
                return { ...prev, page: prev.page - 1 };
            }
            if (prev.page !== dest) {
                return { ...prev, page: dest };
            }
            return prev;
        }),
    });
    const fetchData = async (data, search = '') => {
        try {
            let manualData = Array.isArray(data) ? data : await data();
            const start = (page.page - 1) * page.pageSize;
            const end = start + page.pageSize;
            if (initialPage && initialPage.total) {
                const preFillItems = createEmptyArrayItems(start);
                const trailingFillItems = createEmptyArrayItems(initialPage.total - end);
                manualData = [...preFillItems, ...manualData, ...trailingFillItems];
            }
            const slicedData = manualData.slice(start, end).filter(Boolean);
            const returnResults = {
                ...results,
                data: slicedData,
                error: null,
                filters: { search },
                isError: false,
                isLoading: false,
                pagination: {
                    ...page,
                    hasPrevPage: start > 0,
                    hasNextPage: end < manualData.length,
                    total: manualData.length,
                },
            };
            setResults(returnResults);
            setTableStateItem('data', returnResults.data);
            RoutableEventsSync.Publish(`table:${tableName}:manual:paging`, returnResults);
            RoutableEventsSync.Publish(`table:${tableName}:manual:data`, fullData || []);
        }
        catch (err) {
            setResults({
                ...results,
                isLoading: false,
                isError: true,
                error: err,
            });
        }
    };
    const searchData = useCallback((search) => {
        if (!fullData) {
            return;
        }
        if (!search) {
            fetchData(fullData);
            return;
        }
        const filteredData = fullData.filter((lineItem) => lineItem?.account?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            lineItem?.item?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            lineItem?.description?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()));
        fetchData(filteredData, search);
    }, [fullData]);
    useEffect(() => {
        fetchData(fullData || []);
    }, [page, fullData]);
    useEventSync(`table:${tableName}:manual:paging`, setResults);
    useEventSync(`table:${tableName}:page:update`, ({ page: pageSync, pageSize }) => {
        if (reactTableSagaActions && reactTableSagaActions.onFetchItems) {
            if (pageSize) {
                return reactTableSagaActions.onFetchItems({ page: { size: pageSize } });
            }
            if (pageSync === 'NEXT' && reactTableSagaActions.pagination?.next) {
                return reactTableSagaActions.onFetchItems({ url: reactTableSagaActions.pagination?.next });
            }
            if (pageSync === 'PREV' && reactTableSagaActions.pagination?.prev) {
                return reactTableSagaActions.onFetchItems({ url: reactTableSagaActions.pagination?.prev });
            }
            if (typeof pageSync === 'number') {
                return reactTableSagaActions.onFetchItems({ page: { number: pageSync } });
            }
        }
    });
    return {
        ...results,
        ...(isLoading !== undefined ? { isLoading } : {}),
        searchData,
    };
};
