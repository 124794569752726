import { CheckmarkFilled, Undefined, WarningAltFilled } from '@carbon/icons-react';
import { Spinner, Tooltip } from '@routable/gross-ds';
import pluralize from 'pluralize';
import React from 'react';
export const LineItemDiscrepancyStatusPresenter = ({ discrepancy, isLoading, isSimilarToDefaultLineItem, purchaseOrderLineItemId, }) => {
    if (isSimilarToDefaultLineItem) {
        return (React.createElement("div", null,
            React.createElement(Undefined, { className: "w-5 h-5 text-grey-50", title: "none" })));
    }
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(Spinner, { size: 3 })));
    }
    const hasNoDiscrepancy = !discrepancy && purchaseOrderLineItemId;
    const numOfDiscrepancies = Object.keys(discrepancy || {}).filter((key) => !['bill_line_item_id', 'purchase_order_line_item_id', 'type'].includes(key)).length;
    let tooltip = 'Not found on PO';
    if (hasNoDiscrepancy) {
        tooltip = 'No discrepancies detected';
    }
    else if (numOfDiscrepancies) {
        tooltip = `${pluralize('Discrepancy', numOfDiscrepancies)} detected`;
    }
    return (React.createElement(Tooltip, { "data-testid": "discrepancy-status-tooltip", tooltip: tooltip, variant: "light" }, hasNoDiscrepancy ? (React.createElement(CheckmarkFilled, { className: "w-5 h-5 text-green-40", title: "success" })) : (React.createElement(WarningAltFilled, { className: "w-5 h-5 text-gold-40", title: "warning" }))));
};
