import { horizontalResizeEventName } from '@routable/components';
import { useEvent } from '@routable/framework';
import classNames from 'clsx';
import React from 'react';
import { Field } from 'redux-form';
import FormControl from 'components/form/FormControl';
import { CompanyTypeSettings } from 'constants/ui';
import { useOnElementResize } from 'hooks';
import Radio from '../Radio';
import './HorizontalRadios.scss';
const HorizontalRadios = ({ isDisabled, name, leftOptions, rightOptions, stackOnMobile = true, stackWidth = CompanyTypeSettings.HORIZONTAL_MINIMUM_WIDTH, className, ...rest }) => {
    const commonProps = {
        component: Radio,
        isDisabled,
        isHorizontalLayout: true,
        name,
        type: 'radio',
    };
    const formControlId = 'formControlForHorizontalRadios';
    const [width] = useOnElementResize(formControlId);
    const isSmall = useEvent(horizontalResizeEventName('horizontal:bill:coding'), (ev) => ev.rightSize < CompanyTypeSettings.HORIZONTAL_MINIMUM_WIDTH, false);
    const classes = {
        flex: true,
        stack: isSmall || (stackOnMobile && width <= stackWidth),
        'flex-wrap': isSmall,
        '!m-0': isSmall,
        'gap-5': true,
        '[&>*]:!mr-0': true,
        '[&>*]:!flex-shrink': true,
        'py-3': isSmall,
    };
    return (React.createElement(FormControl, { className: classNames(className, classes), id: formControlId, ...rest },
        React.createElement(Field, { ...commonProps, ...leftOptions }),
        React.createElement(Field, { className: "second-radio-option", ...commonProps, ...rightOptions })));
};
export default HorizontalRadios;
