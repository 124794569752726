import { z } from 'zod';
export const internationalBankFieldCategory = z.enum(['bank_account', 'beneficiary']);
export const internationalBeneficiaryFieldName = z.enum(['country_of_nationality', 'in_fcra', 'residential_status']);
export const internationalBeneficiaryFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.beneficiary),
    field: internationalBeneficiaryFieldName,
});
export const internationalBankAccountFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.bank_account),
    field: z.enum([
        'aba',
        'account_number',
        'bank_code',
        'bic_swift',
        'branch_code',
        'bsb_code',
        'clabe',
        'cnaps',
        'iban',
        'ifsc',
        'sort_code',
    ]),
});
export const internationalBankFieldModel = z.discriminatedUnion('category', [
    internationalBankAccountFieldModel,
    internationalBeneficiaryFieldModel,
]);
export const internationalBankFieldsApiResponseModel = z.object({
    data: z.array(internationalBankFieldModel),
});
