import { ItemFilterUrlParams } from 'enums/itemFilters';
import { and, lengthOf, hasLength, allKeys, isEqual } from 'helpers/utility';
import {} from 'interfaces/itemFilters/filter';
import { FilterOperator } from 'interfaces/itemFilters/filterOperators';
import { createAmountDueFilter, createBankAccountFilter, createBulkActionFilter, createBulkActionStatusFilter, createCreatedDateFilter, createCurrentApproverFilter, createCurrentApproverStatusFilter, createDateDueFilter, createInvoiceNumbersFilter, createItemStatusFilter, createPaymentFilter, } from 'modules/itemFilters/filterFactories';
export const filterParamPostfixesMap = {
    __gt: FilterOperator.GREATER_THAN_OR_EQUAL,
    __gte: FilterOperator.GREATER_THAN_OR_EQUAL,
    __lt: FilterOperator.LESS_THAN_OR_EQUAL,
    __lte: FilterOperator.LESS_THAN_OR_EQUAL,
    __iexact: FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST,
    __icontains: FilterOperator.CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST,
    __istartswith: FilterOperator.STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST,
    __iendswith: FilterOperator.ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST,
};
export const filterParamsMap = {
    [ItemFilterUrlParams.FILTER_AMOUNT_DUE_URL_PARAM]: createAmountDueFilter,
    [ItemFilterUrlParams.FILTER_BANK_ACCOUNT_URL_PARAM]: createBankAccountFilter,
    [ItemFilterUrlParams.FILTER_BULK_ACTION_URL_PARAM]: createBulkActionFilter,
    [ItemFilterUrlParams.FILTER_BULK_ACTION_STATUS_URL_PARAM]: createBulkActionStatusFilter,
    [ItemFilterUrlParams.FILTER_CREATED_DATE_URL_PARAM]: createCreatedDateFilter,
    [ItemFilterUrlParams.FILTER_PAYMENT_APPROVERS_URL_PARAM]: createCurrentApproverFilter,
    [ItemFilterUrlParams.FILTER_APPROVER_STATUS_URL_PARAM]: createCurrentApproverStatusFilter,
    [ItemFilterUrlParams.FILTER_DATE_DUE_URL_PARAM]: createDateDueFilter,
    [ItemFilterUrlParams.FILTER_INVOICE_NUMBERS_URL_PARAM]: createInvoiceNumbersFilter,
    [ItemFilterUrlParams.FILTER_ITEM_STATUS_URL_PARAM]: createItemStatusFilter,
    [ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM]: createPaymentFilter,
};
export const getParamAndFilterOperatorFromKey = (key) => allKeys(filterParamPostfixesMap).reduce((result, postfix) => {
    if (isEqual(key.substr(-postfix.length), postfix)) {
        return [key, key.substr(0, key.length - postfix.length), filterParamPostfixesMap[postfix]];
    }
    return result;
}, [key, key, null]);
export const createFilterListFromParams = (params) => {
    const filterList = [];
    const filterParamValues = [];
    const filterParamsByKey = {};
    allKeys(params).forEach((key) => {
        const [paramWithPostfix, paramName, filterOperator] = getParamAndFilterOperatorFromKey(key);
        if (allKeys(filterParamsMap).includes(paramName)) {
            const value = params[paramWithPostfix];
            if (!hasLength(filterParamsByKey[paramName])) {
                filterParamsByKey[paramName] = [];
            }
            filterParamsByKey[paramName].push({
                paramName,
                filterOperator,
                value,
            });
        }
    });
    allKeys(filterParamsByKey).forEach((paramName) => {
        const filterEntries = filterParamsByKey[paramName];
        if (isEqual(lengthOf(filterEntries), 2)) {
            const lte = filterEntries
                .filter((entry) => isEqual(entry.filterOperator, FilterOperator.LESS_THAN_OR_EQUAL))
                .pop();
            const gte = filterEntries
                .filter((entry) => isEqual(entry.filterOperator, FilterOperator.GREATER_THAN_OR_EQUAL))
                .pop();
            if (and(lte, gte)) {
                filterParamsByKey[paramName] = [
                    {
                        paramName,
                        filterOperator: FilterOperator.BETWEEN,
                        value: [gte.value, lte.value],
                    },
                ];
            }
        }
    });
    allKeys(filterParamsByKey).forEach((paramName) => {
        const filterEntries = filterParamsByKey[paramName];
        filterEntries.forEach((entry) => {
            filterParamValues.push([entry.paramName, entry.filterOperator, entry.value]);
        });
    });
    filterParamValues.forEach(([paramName, filterOperator, value]) => {
        try {
            filterList.push(filterParamsMap[paramName](value, filterOperator));
        }
        catch (e) {
        }
    });
    return filterList;
};
