import { Close } from '@carbon/icons-react';
import { Button, Modal } from '@routable/gross-ds';
import React, { useRef } from 'react';
import { useTabList, useTabPanel, FocusScope } from 'react-aria';
import {} from '../../Filters.types';
import { FiltersListItem } from '../FiltersListItem';
import { FilterTag } from '../FilterTag';
const FiltersModalContent = ({ _forceHiddenFilter, activeFilters, activeFiltersList, filtersModalState, onApplyFilters, onClearAllFilters, onClearFilter, tabsState, ...props }) => {
    const tabsRef = useRef(null);
    const { tabListProps } = useTabList(props, tabsState, tabsRef);
    const { tabPanelProps } = useTabPanel(props, tabsState, tabsRef);
    const hasActiveFilters = !!activeFiltersList.length;
    const activePanelTitle = tabsState.selectedItem.props?.title ?? `Filter by ${tabsState.selectedItem.textValue?.toLowerCase()}`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "aria-label": "close", className: "absolute top-[16px] right-[16px]", "data-testid": "filters-close-button", onPress: filtersModalState.close, variant: "outline" },
            React.createElement(Close, null)),
        React.createElement("section", { className: "!grid grid-cols-[225px_1fr]" },
            React.createElement("aside", { ...tabListProps, className: "row-span-2 border-r border-grey-10 flex flex-col gap-3 p-5", ref: tabsRef }, Array.from(tabsState.collection).map((tab) => (React.createElement(FiltersListItem, { isActive: Boolean(activeFilters[tab.key]), key: tab.key, tab: tab, state: tabsState })))),
            React.createElement(FocusScope, { contain: true, restoreFocus: true, autoFocus: true },
                React.createElement("main", { ...tabPanelProps, className: "p-5 min-h-[280px]" },
                    React.createElement("h2", { className: "text-black text-2xl font-normal mt-2 mb-7" }, activePanelTitle),
                    _forceHiddenFilter !== tabsState.selectedKey && tabsState.selectedItem.props.children),
                React.createElement("footer", { "aria-label": "filters footer", className: "bg-grey-5 p-5 col-start-2 border-t border-grey-10" },
                    hasActiveFilters && (React.createElement("div", { className: "flex flex-wrap gap-4 mb-5" }, activeFiltersList.map(({ filter, value }) => {
                        const item = tabsState.collection.getItem(filter);
                        const handleRemoveTag = (val) => {
                            onClearFilter(filter, val, { shouldApplyFilters: false }, tabsState.selectedItem?.key);
                        };
                        if (!item) {
                            return null;
                        }
                        return (React.createElement(FilterTag, { key: filter, label: item.textValue }, item.props.renderTags({
                            filterName: filter,
                            value,
                            onRemove: handleRemoveTag,
                        })));
                    }))),
                    React.createElement("div", { className: "flex justify-end gap-4" },
                        React.createElement(Button, { "data-testid": "filters-clear-button", onPress: () => onClearAllFilters(), variant: "outline" }, "Clear"),
                        React.createElement(Button, { "data-testid": "filters-apply-button", onPress: onApplyFilters }, "Apply filters")))))));
};
export const FiltersModal = ({ filtersModalState, ...rest }) => (React.createElement(Modal, { className: "fixed z-[250]", state: filtersModalState },
    React.createElement(Modal.Backdrop, null),
    React.createElement(Modal.Body, { className: "absolute bg-white left-1/2 top-[200px] transform -translate-x-1/2 w-[800px] rounded-lg overflow-hidden" },
        React.createElement(FiltersModalContent, { ...rest, filtersModalState: filtersModalState }))));
