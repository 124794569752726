import React from 'react';
import { formNamesFunding } from 'constants/forms';
import { useBankAccountReceivingCurrency } from 'hooks';
import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';
import { itemCurrencyCodeReceiverFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
import {} from './AccountCurrencyCodeField.types';
const AccountCurrencyCodeField = ({ availableCurrencies }) => {
    const { fieldLabel, fieldName, isLocked, tooltipText, options } = useBankAccountReceivingCurrency({
        formName: formNamesFunding.ADD_MANUAL_BANK,
        availableCurrencies,
        itemCurrencyCodeSelector: itemCurrencyCodeReceiverFromQuerySelector,
        partnershipSelector: partnershipFromQuerySelector,
    });
    return (React.createElement(VendorReceivingCurrency, { className: "margin-top--m-large", fieldLabel: fieldLabel, fieldName: fieldName, isLocked: isLocked, options: options, tooltipText: tooltipText }));
};
export default AccountCurrencyCodeField;
