import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { ManuallyAddBankAccountField } from 'modules/connectBank/ManuallyAddBankAccountSideSheet/components';
import AccountCurrencyCodeField from './components/AccountCurrencyCodeField';
const InternationalPartnerFundingAccountBankDetails = ({ country, fields, availableCurrencies, partnership, }) => (React.createElement("div", { className: "international-partner-funding-account__bank-details" },
    React.createElement(Text.Semibold, { color: typography.TextColor.GREY_XX_DARK, weight: typography.TextWeight.LEVEL_600 }, "Bank account details"),
    React.createElement("div", null,
        React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK }, `This bank account must be located in ${country}.`)),
    React.createElement(AccountCurrencyCodeField, { availableCurrencies: availableCurrencies, partnership: partnership }),
    fields?.map((fieldInfo) => (React.createElement(ManuallyAddBankAccountField, { fieldInfo: fieldInfo, isInternational: true, key: fieldInfo.field })))));
export default InternationalPartnerFundingAccountBankDetails;
