import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { change } from 'redux-form';
import useSelectorWithProps from 'hooks/useSelectorWithProps/useSelectorWithProps';
import { commonFormFields } from 'constants/formFields';
import { ItemCurrencyCodeKey } from 'enums/items';
import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { isExternalPathAny } from 'helpers/external';
import {} from './useBankAccountReceivingCurrency.types';
export const useBankAccountReceivingCurrency = ({ formName, availableCurrencies, itemCurrencyCodeSelector, partnershipSelector, }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const isExternalFlow = isExternalPathAny();
    const itemCurrencyCodeReceiver = useSelector(itemCurrencyCodeSelector);
    const partnership = useSelectorWithProps(partnershipSelector, params?.id);
    const defaultCurrencyCodeKey = isExternalFlow ? ItemCurrencyCodeKey.COMPANY : ItemCurrencyCodeKey.PARTNER;
    const partnershipCurrencyCode = partnership?.[defaultCurrencyCodeKey];
    const activeCurrencyCode = itemCurrencyCodeReceiver || partnershipCurrencyCode;
    const hasSingleOption = availableCurrencies?.length === 1;
    const options = availableCurrencies?.map(mapCurrencyCodeToSelectOption);
    const tooltipText = hasSingleOption ? `Receiving currency has been set to ${availableCurrencies[0]}` : undefined;
    useEffect(() => {
        dispatch(change(formName, commonFormFields.CURRENCY_CODE, activeCurrencyCode));
    }, [dispatch, formName, activeCurrencyCode]);
    return {
        fieldLabel: 'Account Currency',
        fieldName: commonFormFields.CURRENCY_CODE,
        isLocked: hasSingleOption,
        options,
        tooltipText,
    };
};
