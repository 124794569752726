import classNames from 'classnames';
import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import ManuallyAddBankAccountField from '../ManuallyAddBankAccountField';
import AccountCurrencyCodeField from './components/AccountCurrencyCodeField';
const ManuallyAddBankAccountBankDetails = ({ availableCurrencies, fields, includeMarginTop, isInternational, }) => {
    const containerClassNames = classNames('manually-add-bank-account--bank-details', {
        'margin-top--x-large': includeMarginTop,
    });
    return (React.createElement("div", { className: containerClassNames },
        isInternational && (React.createElement(React.Fragment, null,
            React.createElement(Text.Bold, { color: typography.TextColor.BLUE_DARK, size: typography.TextSize.LEVEL_475 }, "Bank account details"),
            React.createElement(AccountCurrencyCodeField, { availableCurrencies: availableCurrencies }))),
        fields?.map((fieldInfo) => (React.createElement(ManuallyAddBankAccountField, { fieldInfo: fieldInfo, isInternational: isInternational, key: fieldInfo.field })))));
};
export default ManuallyAddBankAccountBankDetails;
