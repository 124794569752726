import { Email } from '@carbon/icons-react';
import { Button, Tooltip } from '@routable/gross-ds';
import React from 'react';
import { getPartnershipResendButtonProps } from 'helpers/partnerships';
import {} from './ResendPartnershipInvite.types';
export const ResendPartnershipInvite = ({ id, onResendInvite, partnership, resendAction, variant = 'floating', }) => {
    const { buttonText, isDisabled, tooltip } = getPartnershipResendButtonProps(partnership, resendAction);
    const handleResendInvite = () => {
        onResendInvite({ id, partnership });
    };
    return (React.createElement(Tooltip, { "data-testid": "resend-partnership-invite--tooltip", tooltip: tooltip },
        React.createElement(Button, { "data-testid": "resend-partnership-invite--button", disabled: isDisabled, onPress: handleResendInvite, size: "small", variant: variant },
            React.createElement(Email, null),
            buttonText)));
};
