/**
 * @module sagas/tables/sagas
 */

import { call, put, select, take, takeLatest, takeEvery } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as routines from 'actions/routines/tables';
import { toggleAllTableRowsSelection } from 'actions/tables';

import { parseItemStructure } from 'data/parse/item';

import { isCurrentCompanyAccountVerified } from 'helpers/currentCompany';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { findById } from 'helpers/utility/composite';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { itemsArraySelector } from 'selectors/itemsSelectors';

import * as tableTypes from 'types/tables';

import * as api from './api';

/**
 * Fetch a dynamic table configuration.
 * @return {IterableIterator<*>}
 */
export function* fetchItemStructureTables() {
  let errorData = {};

  yield put(routines.fetchItemStructureTablesRoutine.request());

  const currentCompany = yield select(currentCompanySelector);

  if (!isCurrentCompanyAccountVerified(currentCompany)) {
    // The current company isn't verified, therefore we're dispatching a failure on this call
    // The BE will 403 if we try to call it before verification
    // We pass in an empty object since there's no real failure/error payload
    yield put(routines.fetchItemStructureTablesRoutine.failure({}));
    return;
  }

  try {
    const response = yield call(api.fetchItemStructureTables);

    if (response.ok) {
      const ledger = yield select(ledgerIntegrationSelector);
      const parsedTableConfigs = parseItemStructure.getParsedCreateItemsTableConfigs(
        response.originalData.data,
        ledger,
      );
      yield put(routines.fetchItemStructureTablesRoutine.success(parsedTableConfigs));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(routines.fetchItemStructureTablesRoutine.failure, errorData));
}

export function* shiftSelect(action) {
  const lastId = action.payload?.item?.id;

  const { type, payload, meta } = yield take([
    tableTypes.SELECT_SINGLE_PAYABLES_TABLE_ROW,
    tableTypes.DESELECT_SINGLE_PAYABLES_TABLE_ROW,
    tableTypes.SELECT_SINGLE_RECEIVABLES_TABLE_ROW,
    tableTypes.DESELECT_SINGLE_RECEIVABLES_TABLE_ROW,
  ]);
  const shift = meta?.modifierKeys?.shift;
  const currentId = payload?.item?.id;

  if (shift && lastId) {
    const tab = type
      .match(/\/\w+\//g)[0]
      .slice(1, -1)
      .toLowerCase();
    const areAllSelected = type.includes('DESELECT');
    const areSomeSelected = areAllSelected;
    const allRows = yield select(itemsArraySelector);
    const lastRowIndex = allRows.findIndex(findById(lastId));
    const currentRowIndex = allRows.findIndex(findById(currentId));
    if (lastRowIndex !== -1 && currentRowIndex !== -1) {
      const lowIndex = Math.min(lastRowIndex, currentRowIndex);
      const highestIndex = Math.max(lastRowIndex, currentRowIndex);
      const toggleRows = allRows.slice(lowIndex, highestIndex + 1);
      yield put(
        toggleAllTableRowsSelection({
          areAllSelected,
          areSomeSelected,
          items: toggleRows,
          tab,
          meta,
        }),
      );
    }
  }
}

/**
 * Listens for redux actions related to tables and uses takeLatest to prevent duplicate calls from being made.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  yield takeLatest(routines.fetchItemStructureTablesRoutine.TRIGGER, fetchItemStructureTables);

  yield takeEvery(
    [
      tableTypes.SELECT_SINGLE_PAYABLES_TABLE_ROW,
      tableTypes.DESELECT_SINGLE_PAYABLES_TABLE_ROW,
      tableTypes.SELECT_SINGLE_RECEIVABLES_TABLE_ROW,
      tableTypes.DESELECT_SINGLE_RECEIVABLES_TABLE_ROW,
    ],
    shiftSelect,
  );
}

/**
 * Root tables saga.
 * @return {IterableIterator<*>}
 */
export default function* tables() {
  yield watch();
}
