import { CurrencyCodeUsd } from 'constants/currency';
import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { reduceKeys } from 'helpers/utility';
import { usePartnershipPaymentCurrencies } from 'hooks';
import {} from './usePartnershipPaymentOptions.types';
export const usePartnershipPaymentOptions = ({ partnershipId, }) => {
    const { data, isFetching } = usePartnershipPaymentCurrencies(partnershipId);
    const currencyCodesBillSelectable = data?.currencyCodesBillSelectable;
    const currencyCodesBill = currencyCodesBillSelectable?.length ? currencyCodesBillSelectable : [CurrencyCodeUsd];
    const currencyCodesBillOptions = currencyCodesBill.map(mapCurrencyCodeToSelectOption);
    const mapCurrencyReceiverToOptions = (routePayable) => mapCurrencyCodeToSelectOption(routePayable.currencyCodeReceiver);
    const receiverOptions = reduceKeys(data?.routesPayable, (accumulator, key) => ({
        ...accumulator,
        [key.toUpperCase()]: data.routesPayable[key].map(mapCurrencyReceiverToOptions),
    }), {});
    return {
        currencyCodesBillOptions,
        isFetching,
        vendorCurrencyCodeReceiverOptionsByBillCurrency: receiverOptions,
    };
};
