import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formNamesFunding } from 'constants/forms';
import { getInitialAddPartnerFundingAccountValues } from 'helpers/addPartnerFundingAccount';
import {} from 'interfaces/global';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import { addPartnerInternationalFundingAccountFormMetaCompoundSelector } from 'queries/addPartnerFundingAccountCompoundSelectors';
import { fundingAccountsByIdSelector } from 'selectors/fundingSelectors';
import { modalAddInternationalPaymentIsOpenSelector } from 'selectors/modalsSelector';
import { partnershipFundingAccountsForPartnershipIdSelector } from 'selectors/partnershipsSelectors';
import AddInternationalPartnerFundingAccountForm from './AddInternationalPartnerFundingAccountForm';
export const mapStateToProps = (state, ownProps) => {
    const formMeta = addPartnerInternationalFundingAccountFormMetaCompoundSelector(state);
    const isModal = modalAddInternationalPaymentIsOpenSelector(state);
    const { partnership } = ownProps;
    const partnershipFundingAccounts = partnershipFundingAccountsForPartnershipIdSelector(state, partnership.id);
    const fundingAccountsById = fundingAccountsByIdSelector(state);
    return {
        ...formMeta,
        initialValues: getInitialAddPartnerFundingAccountValues({
            partnership,
            partnershipFundingAccounts,
            isInternational: true,
            options: { fundingAccountsById, partnership },
            isModal,
        }),
        partnershipFundingAccounts,
    };
};
const createForm = reduxForm({
    form: formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
    onSubmitFail: makeFormSubmitFailHandler(formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT),
})(AddInternationalPartnerFundingAccountForm);
export default connect(mapStateToProps, null, null)(createForm);
