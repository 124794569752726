import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RestrictedPermissionContent from 'components/RestrictedPermissionContent';
import { TooltipPadding } from 'constants/tooltip';
import { redirectToTeamMembersPage } from 'helpers/navigation';
import { checkMemberHasRequiredPermissions } from 'helpers/permissions';
import { currentMemberPermissionsSetSelector } from 'queries/membershipPermissionCompoundSelectors';
const usePermissions = (permissions) => {
    const history = useHistory();
    const currentMemberPermissionSet = useSelector(currentMemberPermissionsSetSelector);
    const hasPermission = !permissions?.length
        ? true
        : checkMemberHasRequiredPermissions({
            actualMemberPermissionSet: currentMemberPermissionSet,
            requiredPermissions: permissions,
        });
    const onNavigateToTeamMemberPage = React.useCallback(() => {
        redirectToTeamMembersPage(history);
    }, [history]);
    const tooltipProps = React.useMemo(() => {
        if (hasPermission) {
            return undefined;
        }
        return {
            arrow: true,
            padding: TooltipPadding.LARGE,
            title: React.createElement(RestrictedPermissionContent, { onNavigate: onNavigateToTeamMemberPage }),
            'data-testid': 'tooltip--missing-permissions',
        };
    }, [hasPermission, onNavigateToTeamMemberPage]);
    return { hasPermission, tooltipProps };
};
export const useGrossDsPermissions = (permissions) => {
    const { hasPermission, tooltipProps } = usePermissions(permissions);
    return {
        hasPermission,
        tooltipProps: {
            tooltip: tooltipProps?.title,
            'data-testid': tooltipProps?.['data-testid'],
        },
    };
};
export default usePermissions;
