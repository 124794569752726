import {} from '@routable/framework';
import React from 'react';
import { TablematicTableHeader, useTablematicTableColumnSelect } from './components';
import { TablematicTableRows } from './components/rows/tablematic.table.rows.component';
import { tablematicMinFieldSizeMap } from './helpers/tablematic.table.helpers';
import { TableSection, TableSectionWrap } from './tablematic.table.styled';
import { usePreventOverscrollLeft } from '../Table/hooks/usePreventOverscrollLeft.hook';
import { useTablematicStickyScroll } from '../Table/hooks/useTablematicStickyScroll.hook';
export const TablematicTableSection = ({ dataRow, getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize, isOnDetailsPage, sectionRef, tableSection, }) => {
    const tableProps = tableSection.tableProps;
    const subSectionId = tableSection.id;
    const scrollableRef = React.useRef(null);
    const { headerContainerRef, rowContainerRef, innerWidth } = useTablematicStickyScroll();
    usePreventOverscrollLeft(scrollableRef);
    const currentHeadersConfigForUseTablematicTable = tableProps.headers.map(({ id, isVisible, Header }) => ({
        id,
        Header,
        isVisible,
    }));
    const { headersConfig, headersConfigById, columnSelectProps } = useTablematicTableColumnSelect({
        headersConfig: currentHeadersConfigForUseTablematicTable,
        tableName: subSectionId,
    });
    let widthStyle = {};
    if (subSectionId !== 'line_items_item' && subSectionId !== 'line_items_account') {
        const maxWidthSectionSize = (window.innerWidth - 48) / 2;
        const sectionSize = headersConfig
            .filter(({ isVisible }) => isVisible)
            .reduce((prev, { id }) => {
            const fieldSize = getTableSectionFieldSize(tableSection.fieldProps[id]);
            return prev + tablematicMinFieldSizeMap[fieldSize];
        }, 0);
        const widthSize = tableProps.sectionWidth || (sectionSize >= maxWidthSectionSize ? maxWidthSectionSize : sectionSize + 2);
        widthStyle = sectionSize ? { flex: `0 0 ${tableSection.sectionWidth || widthSize}px` } : {};
    }
    const CustomSectionHeaderComponent = tableSection.CustomSectionHeaderComponent;
    const CustomRowComponent = tableSection.CustomRowComponent;
    return (React.createElement(TableSection, { ref: sectionRef, key: `${subSectionId}.section`, style: { ...tableSection.sectionStyle, ...widthStyle }, className: `tablematic-table table-section table-section-${subSectionId} allow-scroll use-scrollbars horizontal-scroll-group` },
        CustomSectionHeaderComponent && (React.createElement("div", null,
            React.createElement(CustomSectionHeaderComponent, { columnSelectProps: columnSelectProps, fieldProps: tableSection.fieldProps, getTableSectionFieldSize: getTableSectionFieldSize, isOnDetailsPage: isOnDetailsPage, section: tableProps }))),
        React.createElement(TableSectionWrap, { ref: scrollableRef, className: "allow-scroll use-scrollbars horizontal-scroll-group" },
            React.createElement(TablematicTableHeader, { headerContainerRef: headerContainerRef, fieldProps: tableSection.fieldProps, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: sectionRef, isOnDetailsPage: isOnDetailsPage, section: tableProps }),
            React.createElement(TablematicTableRows, { CustomRowComponent: CustomRowComponent, dataRow: dataRow, fieldProps: tableSection.fieldProps, getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getRowValues: dataRow?.getRowValues, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: sectionRef, innerWidth: innerWidth, isOnDetailsPage: isOnDetailsPage, rowContainerRef: rowContainerRef, section: tableSection }))));
};
