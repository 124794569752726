import { InvoiceGeneratorTableBody, InvoiceGeneratorTableBodyCell, InvoiceGeneratorTableBodyRow, InvoiceGeneratorTableContainer, InvoiceGeneratorTableHeader, InvoiceGeneratorTableHeaderCell, InvoiceGeneratorTableHeaderRow, } from 'modules/table/components';
export const tableComponents = {
    Body: InvoiceGeneratorTableBody,
    BodyCell: InvoiceGeneratorTableBodyCell,
    BodyRow: InvoiceGeneratorTableBodyRow,
    Container: InvoiceGeneratorTableContainer,
    Footer: () => null,
    Header: InvoiceGeneratorTableHeader,
    HeaderCell: InvoiceGeneratorTableHeaderCell,
    HeaderRow: InvoiceGeneratorTableHeaderRow,
};
