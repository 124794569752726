import {} from '../models';
export const internationalFieldsBaseQueryKey = 'internationalFields';
export const routeRulesBaseQueryKey = 'routeRules';
export const generateKeys = {
    routeRulesForCurrencyCode: (currencyCode, countryCode) => [
        routeRulesBaseQueryKey,
        currencyCode,
        { countryCode },
    ],
    internationalBankFieldsForCurrencyCode: (currencyCode, countryCode, businessType) => [internationalFieldsBaseQueryKey, currencyCode, { countryCode, businessType }],
};
